<template>
<div>
  <v-dialog v-model="showDialog" width="800" persistent>
  <v-card class="add-menu containerbox overflow-x-hidden">
    <v-card-title class="subtitle-2 primary customwhite--text">
     Add Tier 2 Failure Code 
      <v-spacer></v-spacer>
      <v-btn outlined class="pa-0" dense small title="Close" color="customwhite" @click="closeDialog()">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <template>
      <v-form ref="Tier2Form"  lazy-validation @submit.prevent="submit">
        <v-row class="pt-5">
          <v-col sm="6" md="6" lg="4" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.status" inset label="Status"  color="primarytext"
              class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="8" class="pr-10 pt-5">
            <v-text-field color="primarytext" @input="tier2Data.codeName=tier2Data.codeName.toUpperCase()" v-model="tier2Data.codeName" label="Tier 2 Failure Code"
              outlined dense :rules="max10Rule" persistent-placeholder>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-text-field color="primarytext" v-model="tier2Data.codeDesc" label="Description" outlined dense
              :rules="max100Rule" persistent-placeholder>
            </v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
          <v-select :rules="required" v-model="tier2Data.fc1Code" :items="failCodeDesc" item-value="failc_id"
            item-text="description" outlined dense label="Tier 1 Code"
             persistent-placeholder>
          </v-select>
          </v-col>
          <v-col sm="6" md="6" lg="6" class="pr-10 pt-2">
            <v-select v-model="tier2Data.route" :items="routeLoc" item-text="displaystring" item-value="loc"
              outlined dense hide-details label="Route" persistent-placeholder>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="6" class="pl-10 pt-2">
            <v-select v-model="tier2Data.parent" :items="parentClass" item-text="parent_class"
              item-value="parent_class_key" outlined dense hide-details label="Parent Class"
              @change="parentClassChange(tier2Data.parent)" persistent-placeholder :rules="required">
            </v-select>
          </v-col>
          <v-col sm="6" md="6" lg="6" class="pr-10 pt-2">
            <v-select v-model="tier2Data.subClass" :items="subClassItems" outlined dense hide-details
              label="Sub-Class" item-text="class" item-value="class_id" persistent-placeholder
              :rules="required">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.tbe" inset label="TBE" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.bit" inset label="BIT" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.eng" inset label="ENG" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.frm" inset label="FRM" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.rep" inset label="REP" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.irp" inset label="IRP" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.aln" inset label="ALN" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.ftt" inset label="FTT" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.ins" inset label="INS" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.oba" inset label="OBA" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.forceOw" inset label="Force OW" color="primarytext" class="d-inline-block">
            </v-switch>
          </v-col>
          <v-col sm="6" md="6" lg="3" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.bypass" inset label="Bypass FAE" color="primarytext"
              class="d-inline-block">
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="6" lg="6" class="pl-10 pt-2">
            <v-switch v-model="tier2Data.partReq" inset label="Part Request" color="primarytext"
              class="d-inline-block">
            </v-switch>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primarytext" outlined dense class="ma-2 btn-style" elevation="1"
        @click="onClickSubmit()">Submit</v-btn>
      <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click="closeDialog()">Cancel
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
  </v-dialog>
</div>
</template>
<script>
import Utility from "./js/utility-api-code-manager";
import EncryptUtility from "./js/encrypt-utility";
export default {
  name: 'backToTop',
  props: {
    showDialog: Boolean,
    compData:Function,
    closedDialog:Function,
    routeLoc:Array,
    parentClass:Array,
    failCodeDesc:Array,
    buKey:String,
   
  },
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      subClassItems:[],
      required: [(v) => (v !== null && v !== undefined && v!=="") || "Field is required"],
      max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length of 100 character"],
      max10Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 10 || "Max Length of 10 character"],
      tier2Data:{    
        codeName:"",
        fc1Code:"",
        codeDesc:"",
        partReq:false,
        status:true,
        route:"",
        parent:"",
        subClass:"",
        tbe:false,
        bit:false,
        eng:false,
        frm:false,
        rep:false,
        irp:false,
        aln:false,
        ftt:false,
        bypass:false,
        forceOw:false,
        oba:false,
        ins:false,
        
      }
    }
  },
  methods: {
    // Scroll Check method 
    closeDialog(){
      this.showDialog=false;
      this.$emit('closedDialog');
        this.$refs.Tier2Form?.resetValidation();
        this.resetForm()
       
    },
    async parentClassChange(item) {
      this.pass = 6;
      let obj = {
        pass: parseInt(this.pass),
        UserId: parseInt(this.userId),
        bu_key: parseInt(this.buKey),
        parent_class_key: parseInt(item),
      };
      let response = await Utility.onBusinessSelect(obj, false);
      if (response !== null && response !== undefined) {
        this.subClassItems = response.PartClass;
      }
  },
  onClickSubmit(){
    if(this.$refs.Tier2Form.validate()){
    this.$emit('compData',this.tier2Data);
    this.closeDialog()}
  },
  resetForm(){
    this.tier2Data={    
        codeName:"",
        fc1Code:"",
        codeDesc:"",
        partReq:false,
        status:true,
        route:"",
        parent:"",
        subClass:"",
        tbe:false,
        bit:false,
        eng:false,
        frm:false,
        rep:false,
        irp:false,
        aln:false,
        ftt:false,
        bypass:false,
        forceOw:false,
        oba:false,
        ins:false,
        
      }
  }
}
}
</script>
