import axios from 'axios';
import Utility from '../../../shared/utility';
import { store } from '../../../state-management/state-mgmt';
export default {
  getProjectList(pass, userId) {
    let call = 'get';
    return this.getData(`/cp/project_header?pass=${pass}&user_id=${userId}`,call,);
  },
  getBusinessList(obj,showMessage) {
    let call = 'post';
    return this.getData('/co/business_getbyId', call, obj,showMessage);
  },
  onBusinessSelect(obj,showMessage) {
    let call = 'post';
    return this.getData('/ut/code_manager_upsert', call, obj,showMessage);
  },
  statusChangeofItem(obj,showMessage) {
    let call = 'post';
    return this.getData('/ut/code_manager_upsert', call, obj,showMessage);
  },
  addNewItem(obj,showMessage) {
    let call = 'post';
    return this.getData('/ut/code_manager_upsert', call, obj,showMessage);
  },
  async getData(url, call, obj,showMessage) {
    let LoaderDialog = {
      visible: true,
      title: 'Please Wait...',
    };
    store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    let res = {};
    if (call == 'get') {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: 'error',
            isShow: true,
            message: error.response.data.message,
          };
          store.commit('ConfigModule/Alert', Alert);
          LoaderDialog.visible = false;
          store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: 'error',
            isShow: true,
            message: error.response.data.message,
          };
          store.commit('ConfigModule/Alert', Alert);
          LoaderDialog.visible = false;
          store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        LoaderDialog.visible = false;
        store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        return {};
      }
      try {
        if(showMessage){
          let Alert = {
            type: 'success',
            isShow: true,
            message: res.data.message,
          };
          store.commit('ConfigModule/Alert', Alert);
        }
        LoaderDialog.visible = false;
        store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else {
      if (!res?.data?.message) {
        return {};
      }
      try {
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
